<template>
  <div class="col-lg-4 bg-light py-4">
    <!-- find a supervisor -->
    <h4>Find a Supervisor</h4>
    <form class="row g-3" @submit.prevent="$emit('search')">
      <div class="col-12"></div>
      <div class="col-md-6 col-lg-12">
        <div class="form-floating">
          <input
            id="inputLname"
            type="text"
            class="form-control"
            placeholder="Last Name"
            :value="lastName"
            @input="$emit('update:lastName', $event.target.value)"
          />
          <label for="inputLname">Last Name</label>
        </div>
      </div>
      <div class="col-md-6 col-lg-12">
        <div class="form-floating">
          <input
            id="inputCity"
            type="text"
            class="form-control"
            placeholder="City"
            :value="city"
            @input="$emit('update:city', $event.target.value)"
          />
          <label for="inputCity">City</label>
        </div>
      </div>
      <div class="col-8 col-md-4 col-lg-6">
        <div class="form-floating">
          <select
            id="selectState"
            :value="stateCode"
            class="form-select"
            @input="$emit('update:stateCode', $event.target.value)"
          >
            <option value="">Select</option>
            <option value="OH">Ohio</option>
          </select>
          <label for="selectState">State</label>
        </div>
      </div>
      <div class="col-4 col-md-2 col-lg-6">
        <div class="form-floating">
          <input
            id="inputZip"
            type="text"
            class="form-control"
            placeholder="Zip Code"
            :value="postalCode"
            @input="$emit('update:postalCode', $event.target.value)"
          />
          <label for="inputZip">Zip</label>
        </div>
      </div>
      <div class="col-12">
        <div class="form-floating">
          <select
            id="selectSpecialty"
            :value="specialtyId"
            class="form-select"
            @input="$emit('update:specialtyId', $event.target.value)"
          >
            <option value="">Select a specialty</option>
            <option
              v-for="specialty in specialties"
              :key="specialty.specId"
              :value="specialty.specId"
            >
              {{ specialty.name }}
            </option>
          </select>
          <label for="selectSpecialty">Specialization</label>
        </div>
      </div>
      <div class="col-12 text-end">
        <button type="submit" class="btn btn-sm btn-primary">
          Find Supervisors
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    lastName: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    stateCode: {
      type: String,
      required: true,
    },
    postalCode: {
      type: String,
      required: true,
    },
    specialtyId: {
      type: String,
      required: true,
    },
    specialties: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'update:lastName',
    'update:city',
    'update:stateCode',
    'update:postalCode',
    'update:specialtyId',
    'search',
  ],
  setup() {},
};
</script>
