<template>
  <base-modal
    ref="edit-todo-modal"
    :show-btn-classes="{ btn: true, 'btn-sm': true }"
    @modal-opened="refreshModalData"
    @exit="$emit('clean')"
  >
    <template #show-btn-content>
      <i :id="`edit-todo-${todo.id}-btn`" class="fas fa-edit"></i>
    </template>
    <template #header-title>Edit Todo</template>
    <template #body
      ><div class="row">
        <div class="col-md-6">
          <div class="row g-2">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input
                  :id="`todo-title-input-${localTodo.id}`"
                  v-model="localTodo.title"
                  type="text"
                  class="form-control"
                  placeholder="Title"
                />
                <label>Todo Name</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <textarea
                  :id="`todo-description-${localTodo.id}`"
                  v-model="localTodo.description"
                  class="form-control"
                  placeholder="Optional Description"
                ></textarea>
                <label>Description (optional)</label>
              </div>
            </div>
            <div v-if="!localTodo.isRecurring" class="col-12">
              <form class="form-floating mb-3">
                <input
                  :id="`todo-due-date-${localTodo.id}`"
                  v-model="localTodo.dueDateLocal"
                  type="date"
                  class="form-control"
                  placeholder="Due Date"
                />
                <label>Due Date</label>
              </form>
            </div>
            <div class="col-12 d-flex justify-content-start">
              <div class="form-check">
                <input id="hasTimeValue" v-model="hasTimeValue" type="checkbox" class="form-check-input" />
                <label for="hasTimeValue" class="form-check-label">Schedule Time</label>
              </div>
            </div>
            <div v-if="hasTimeValue" class="col-12" >
              <div class="form-floating mb-3">
                <Datepicker v-model="taskTime" :time-picker="true" :is24="false" />
              </div>
            </div>
            <div class="col-12 d-flex justify-content-start">
              <div class="form-check form-check-sm mb-3">
                <input
                  :id="`is-completed-${localTodo.id}`"
                  v-model="localTodo.isCompleted"
                  type="checkbox"
                  class="form-check-input"
                />
                <label
                  :for="`is-completed-${localTodo.id}`"
                  class="form-check-label"
                  >Completed</label
                >
              </div>
            </div>
          </div>
        </div>
      </div></template
    >
    <template #footer>
      <button
        :id="`cancel-edit-btn-${localTodo.id}`"
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        :id="`save-edit-btn-${localTodo.id}`"
        :disabled="!localTodo.title || !localTodo.dueDateLocal"
        type="button"
        class="btn btn-success"
        @click="editTodo"
      >
        Save &amp; Close
      </button>
    </template>
  </base-modal>
</template>

<script>
import { inject, ref } from 'vue';
import BaseModal from '../shared/UI/BaseModal.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import stdLocalDateTime, 
  { 
    setLocalTimeOnLocalDate, 
    getLocalTimeHour,
    getLocalTimeMinutes
  } from '../../../services/lib/localTime';

export default {
  components: {
    BaseModal,
    Datepicker
  },
  props: {
    todo: {
      type: Object,
      required: true,
    },
  },
  emits: ['updated', 'clean', 'dirty'],
  setup(props, context) {
    const userApi = inject('api').userApi();

    const editTodoModal = ref(null);
    const toggleModal = () => {
      editTodoModal.value.toggleModal();
    };

    const localTodo = ref({});
    const hasTimeValue = ref(false);
    const taskTime = ref({ 
      hours: 23,
      minutes: 59
    });

    const refreshModalData = () => {
      localTodo.value = JSON.parse(JSON.stringify(props.todo));

      const localDateIsoFormat = stdLocalDateTime(localTodo.value.dueDateUtc, true, 'YYYY-MM-DD');
      localTodo.value.dueDateLocal = localDateIsoFormat;
      
      hasTimeValue.value = localTodo.value.hasTimeValue;
      if(localTodo.value.hasTimeValue) {
        taskTime.value.hours = getLocalTimeHour(localTodo.value.dueDateUtc);
        taskTime.value.minutes = getLocalTimeMinutes(localTodo.value.dueDateUtc);
      }

      context.emit('dirty');
    };

    const editTodo = async () => {
      const taskHour = hasTimeValue.value ? taskTime.value.hours : 22;
      const taskMinutes = hasTimeValue.value ? taskTime.value.minutes : 59;

      const localDateTime = setLocalTimeOnLocalDate(localTodo.value.dueDateLocal, taskHour, taskMinutes);
      localTodo.value.dueDateUtc = localDateTime.toISOString();
      localTodo.value.hasTimeValue = hasTimeValue.value;

      const id = localTodo.value.taskId ?? localTodo.value.id;
      await userApi.editTodo(id, localTodo.value);
      await userApi.updateTodoCompletion(
        id,
        localTodo.value.isCompleted
      );
      toggleModal();
      context.emit('updated');
      context.emit('clean');
    };

    const cancel = () => {
      context.emit('clean');
      toggleModal();
    };

    return {
      'edit-todo-modal': editTodoModal,
      toggleModal,
      editTodo,
      localTodo,
      refreshModalData,
      cancel,
      hasTimeValue,
      taskTime
    };
  },
};
</script>
