<template>
  <div class="col">
    <div class="card h-100">
      <div class="row g-0">
        <div class="col-12">
          <div class="card-body p-2">
            <h5 class="card-title">
              {{ contact.firstName }} {{ contact.lastName }}<br />
            </h5>

            <div class="text-end">
              <div class="card-text text-start small">
                <div class="d-flex w-full">
                  <div style="min-width:100px">
                    <b> Occupation:</b>
                  </div>
                  <div>
                    {{ contact.occupation || '-'
                    }}
                  </div>
                </div>
                <div class="d-flex w-full">
                  <div style="min-width:100px">
                    <b> Phone:</b>
                  </div>
                  <div>
                    {{ contact.phone || '-'
                    }}
                  </div>
                </div>
                <div class="d-flex w-full">
                  <div style="min-width:100px">
                    <b> Email:</b>
                  </div>
                  <div>

                    {{ contact.email || '-' }}
                  </div>
                </div>
                <div class="d-flex w-full">
                  <div style="min-width:100px">
                    <b> Address:</b>
                  </div>
                  <div>
                    {{ contact.address || '-' }}
                  </div>
                </div>
              </div>
              <div v-if="!deletePending" class="btn-group btn-group-sm">
                <edit-contact-modal :contact="contact" @updated="$emit('updated')" @clean="$emit('clean')"
                  @dirty="$emit('dirty')" />
                <button type="button" class="btn btn-sm" @click="deletePending = true">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
              <div v-else class="btn-group btn-group-sm">
                <button type="button" class="btn btn-sm btn-danger" @click="deleteContact">
                  Delete
                </button>
                <button type="button" class="btn btn-sm btn-secondary ms-2" @click="deletePending = false">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import EditContactModal from './EditContactModal.vue';

export default {
  components: {
    EditContactModal,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  emits: ['delete', 'clean', 'dirty', 'updated'],
  setup(props, context) {
    const deletePending = ref(false);

    const deleteContact = () => {
      context.emit('delete');
    };

    return {
      deletePending,
      deleteContact,
    };
  },
};
</script>
