<template>
  <base-modal ref="edit-contact-modal" :show-btn-classes="{ btn: true, 'btn-sm': true }" @modal-opened="refreshModalData"
    @exit="$emit('clean')">
    <template #show-btn-content>
      <i class="fas fa-edit"></i>
    </template>
    <template #header-title>Edit Contact</template>
    <template #body>
      <div class="row">
        <div class="col-md-6">
          <div class="row g-2">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input :id="`edit-first-name-${localContact.id}`" v-model="localContact.firstName" type="text"
                  class="form-control" placeholder="First Name" />
                <label>First Name</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input :id="`edit-last-name-${localContact.id}`" v-model="localContact.lastName" type="text"
                  class="form-control" placeholder="Last Name" />
                <label>Last Name</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input :id="`edit-email-${localContact.id}`" v-model="localContact.email" class="form-control"
                  placeholder="Email Address">
                <label>Email Address</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input :id="`edit-occupation-${localContact.id}`" v-model="localContact.occupation" class="form-control"
                  placeholder="Occupation">
                <label>Occupation</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input :id="`edit-phone-${localContact.id}`" v-model="localContact.phone" class="form-control"
                  placeholder="Phone">
                <label>Phone</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <textarea style="height: 150px" maxlength="170" :id="`edit-address-${localContact.id}`"
                  v-model="localContact.address" class="form-control" placeholder="Address"></textarea>
                <label>Address</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button :id="`cancel-edit-btn-${localContact.id}`" type="button" class="btn btn-secondary" @click="cancel">
        Cancel
      </button>
      <button :id="`save-edit-btn-${localContact.id}`" type="button" :disabled="!isSaveable" class="btn btn-success"
        @click="editContact">
        Save &amp; Close
      </button>
    </template>
  </base-modal>
</template>

<script>
import { inject, ref, computed } from 'vue';
import BaseModal from '../../../shared/UI/BaseModal.vue';
export default {
  components: {
    BaseModal,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  emits: ['updated', 'clean', 'dirty'],
  setup(props, context) {
    const userApi = inject('api').userApi();

    const editContactModal = ref(null);
    const toggleModal = () => {
      editContactModal.value.toggleModal();
    };

    const localContact = ref({});

    const refreshModalData = () => {
      localContact.value = JSON.parse(JSON.stringify(props.contact));
      context.emit('dirty');
    };

    const editContact = async () => {
      await userApi.editContact(localContact.value);
      toggleModal();
      context.emit('updated');
      context.emit('clean');
    };

    const cancel = () => {
      context.emit('clean');
      toggleModal();
    };

    const isSaveable = computed(() => {
      return (
        localContact.value.firstName ||
        localContact.value.lastName ||
        localContact.value.email ||
        localContact.value.occupation ||
        localContact.value.phone ||
        localContact.value.address
      );
    });

    return {
      'edit-contact-modal': editContactModal,
      toggleModal,
      editContact,
      localContact,
      refreshModalData,
      cancel,
      isSaveable,
    };
  },
};
</script>
