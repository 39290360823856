<template>
  <div class="col">
    <div class="card">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-4 col-lg-5 col-xxl-4 pe-0">
            <img :src="getProfPic(supervisor)" class="img-fluid" alt="..." />
          </div>
          <div class="col">
            <h5 class="card-title mt-2 mb-0">
              {{ supervisor.firstName }} {{ supervisor.lastName }}
            </h5>
            <div class="card-text small">
              <ul class="small lh-sm list-group list-group-flush">
                <li class="list-group-item ps-2 d-flex align-items-start">
                  <i class="fas fa-certificate me-2"></i>
                  {{ supervisor.certifications || '-' }}
                </li>
                <li class="list-group-item ps-2 d-flex align-items-start">
                  <i class="fas fa-mobile-alt me-2"></i>
                  {{ supervisor.phone || '-' }}
                </li>
                <li class="list-group-item ps-2 d-flex align-items-start">
                  <i class="fas fa-map-marker me-2"></i>
                  {{ supervisor.address }} <br v-if="supervisor.address" />{{
                    supervisor.city
                  }}{{
  (supervisor.city && supervisor.stateCode) ||
  (supervisor.city && supervisor.postalCode)
  ? ','
  : ''
}}
                  {{ supervisor.stateCode }} {{ supervisor.postalCode }}
                  {{
                    !supervisor.address &&
                    !supervisor.city &&
                    !supervisor.stateCode &&
                    !supervisor.postalCode
                    ? '-'
                    : ''
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-center">
        <div class="d-grid gap-2 d-md-block">
          <button class="btn btn-sm btn-primary me-md-2" type="button" @click="$emit('view-profile')">
            View Profile
          </button>
          <a :href="`mailto:${supervisor.email}`" class="btn btn-sm btn-primary">
            Send Message
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    supervisor: {
      type: Object,
      required: true,
    },
  },
  emits: ['view-profile'],
  setup() {
    const getProfPic = (supervisor) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${supervisor.id}`;
    };

    return {
      getProfPic,
    };
  },
};
</script>
