<template>
  <div>
  <button
    type="button"
    class="btn btn-primary mb-2"
    @click="$router.push({ name: 'todo-calendar' })"
  >
    Go to Calendar
  </button>
  <div ref="todo-container" class="card mb-3 position-relative">
    <div class="card-header pt-3">
      <div class="btn-toolbar">
        <div class="input-group input-group-sm flex-grow-1 mb-2">
          <input
            id="action-filter"
            v-model.trim="titleFilter"
            type="text"
            class="form-control"
            placeholder="Search Tasks"
            @keyup.enter="searchTasks"
          />
          <button
            id="searchTodos"
            class="btn btn-outline-secondary me-2"
            type="button"
            @click="searchTasks"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>

        <div class="d-flex flex-grow-1 mb-2">
          <div
            v-if="$store.getters.userType == 'patient'"
            class="btn-group btn-group-sm flex-grow-1 me-2"
            role="group"
          >
            <button
              id="todos-only"
              type="button"
              class="btn btn-outline-secondary"
              :class="{ active: taskFilter == 'TodosOnly' }"
              @click="changeTaskFilter('TodosOnly')"
            >
              <i class="fas fa-user"></i>
            </button>
            <button
              id="goal-actions-only"
              type="button"
              class="btn btn-outline-secondary"
              :class="{ active: taskFilter == 'GoalActionsOnly' }"
              @click="changeTaskFilter('GoalActionsOnly')"
            >
              <i class="fas fa-briefcase-medical"></i>
            </button>
          </div>
          <div class="btn-group btn-group-sm flex-grow-1 me-2" role="group">
            <button
              id="completed-only"
              type="button"
              class="btn btn-outline-secondary"
              :class="{ active: taskFilter == 'CompletedOnly' }"
              @click="changeTaskFilter('CompletedOnly')"
            >
              <i class="far fa-check-square"></i>
            </button>
            <button
              id="incomplete-only"
              type="button"
              class="btn btn-outline-secondary"
              :class="{ active: taskFilter == 'IncompleteOnly' }"
              @click="changeTaskFilter('IncompleteOnly')"
            >
              <i class="far fa-square"></i>
            </button>
          </div>
          <div class="btn-group btn-group-sm flex-grow-1" role="group">
            <create-todo-modal
              @add-todo="refresh()"
              @dirty="isDirty = true"
              @clean="isDirty = false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive border-bottom mb-3">
      <table
        class="table table-small align-middle table-borderless table-striped mb-0 lh-sm"
      >
        <thead class="bg-sky text-white">
          <tr>
            <th></th>
            <th
              id="title-sort-btn"
              class="w-100"
              scope="col"
              @click="sortByTitle"
            >
              Item Name
              <i
                v-if="taskOrder == 'TaskTitleAsc'"
                class="fas fa-sort-down"
              ></i>
              <i v-if="taskOrder == 'TaskTitleDesc'" class="fas fa-sort-up"></i>
            </th>
            <th id="due-date-sort-btn" scope="col" @click="sortByDueDate">
              <div class="d-flex">
                Due&nbsp;Date
                <i
                  v-if="taskOrder == 'TaskDueOnAsc'"
                  class="fas fa-sort-down ms-2"
                ></i>
                <i
                  v-if="taskOrder == 'TaskDueOnDesc'"
                  class="fas fa-sort-up ms-2"
                ></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="diversion in diversions"
            :key="`diversion-${diversion.id}`"
            class="diversion-row"
          >
            <td>
              <i
                v-if="diversion.isCompleted"
                class="fas fa-check text-primary p-2"
              ></i>
              <button
                v-else
                :id="`complete-todo-btn-${diversion.id}`"
                type="button"
                class="btn btn-sm"
                @click="completeTodo(diversion)"
              >
                <i class="far fa-square"></i>
              </button>
            </td>
            <td>
              <i class="fas fa-sm fa-rocket text-primary me-1"></i>
              <!-- eslint-disable vue/no-v-html -->
              <span class="ql-editor" v-html="diversion.title"></span>
              <!-- eslint-enable -->
            </td>
            <td class="text-end small">
              <div v-if="toBeDeleted != diversion.id" class="btn-group">
                <button
                  :id="`delete-todo-btn-${diversion.id}`"
                  type="button"
                  class="btn btn-sm"
                  @click="toBeDeleted = diversion.id"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
              <div v-else class="btn-group">
                <button
                  :id="`confirm-delete-btn-${diversion.id}`"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="doDelete(diversion)"
                >
                  Delete
                </button>
                <button
                  :id="`cancel-delete-btn-${diversion.id}`"
                  type="button"
                  class="btn btn-sm btn-secondary"
                  @click="toBeDeleted = null"
                >
                  Cancel
                </button>
              </div>
            </td>
          </tr>
          <tr
            v-for="task in tasks"
            :key="`${task.type}-${task.id}`"
            :class="rowStatus(task)"
          >
            <td>
              <i
                v-if="task.isCompleted"
                class="fas fa-check text-primary p-2"
              ></i>
              <button
                v-else
                :id="`complete-todo-btn-${task.id}`"
                type="button"
                class="btn btn-sm"
                @click="completeTodo(task)"
              >
                <i class="far fa-square"></i>
              </button>
            </td>
            <td>
              <!-- eslint-disable vue/no-v-html -->
              <div class="ql-editor" v-html="task.title"></div>
              <!-- eslint-enable -->
              <p v-if="task.goalTitle" class="p-0 m-0">
                {{ task.goalTitle }}
              </p>
              <p
                v-else-if="task.type == 'Todo' && task.description"
                class="small p-0 m-0"
              >
                {{ task.description }}
              </p>
            </td>
            <td class="text-end small">
              <span v-if="task.dueDt"> {{ task.dueDt }} </span>
              <div v-if="task.type == 'Todo'">
                <div v-if="toBeDeleted != task.id" class="btn-group">
                  <edit-todo-modal
                    :todo="task"
                    @updated="refresh()"
                    @clean="isDirty = false"
                    @dirty="isDirty = true"
                  />
                  <button
                    :id="`delete-todo-btn-${task.id}`"
                    type="button"
                    class="btn btn-sm"
                    @click="toBeDeleted = task.id"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div v-else class="btn-group">
                  <button
                    :id="`confirm-delete-btn-${task.id}`"
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="doDelete(task)"
                  >
                    Delete
                  </button>
                  <button
                    :id="`cancel-delete-btn-${task.id}`"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    @click="toBeDeleted = null"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination -->
    <tbl-page-seln
      v-model="pgNum"
      :total-pages="totalPages"
      @updated="refresh"
    />
  </div>
</div>
</template>

<script>
import { inject, onMounted, ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import TblPageSeln from '../shared/components/TblPageSeln.vue';
import CreateTodoModal from './CreateTodoModal.vue';
import EditTodoModal from './EditTodoModal.vue';
import stdLocalTime from '../../../services/lib/localTime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { onBeforeRouteLeave } from 'vue-router';

export default {
  components: {
    TblPageSeln,
    CreateTodoModal,
    EditTodoModal,
  },
  emits: ['goal-update'],
  setup(props, context) {
    dayjs.extend(utc);
    const patientApi = inject('api').patientApi();
    const profApi = inject('api').profApi();
    const userApi = inject('api').userApi();
    const loading = inject('$loading');
    const store = useStore();

    const TodoContainer = ref(null);
    const pgNum = ref(1);
    const pgSz = ref(10);
    const titleFilter = ref('');
    const taskFilter = ref('IncompleteOnly');
    const taskOrder = ref('TaskDueOnAsc');
    const totalPages = ref(0);
    const tasks = ref([]);
    const diversions = ref([]);
    const toBeDeleted = ref(null);
    const date = new Date();
    const startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).toISOString();
    const endDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).toISOString();
    const refresh = async () => {
      const loader = loading.show({
        container: TodoContainer.value,
        isFullPage: false,
      });
      let response = null;
      if (store.getters.userType == 'patient') {
        response = await patientApi.getTasks(
          pgSz.value,
          pgNum.value - 1,
          startDate,
          endDate,
          titleFilter.value,
          taskOrder.value,
          taskFilter.value
        );
        const diversionResponse = await patientApi.getActiveDiversions();
        diversions.value = diversionResponse.data;
      } else {
        response = await profApi.getTasks(
          pgSz.value,
          pgNum.value - 1,
          titleFilter.value,
          taskOrder.value,
          taskFilter.value
        );
      }

      totalPages.value =
        response.data.totalPages === undefined ? 0 : response.data.totalPages;

       if (response.data !== undefined) {
        response.data.forEach((e) => {
          if (e.dueDateUtc) {
            e.dueDt = stdLocalTime(e.dueDateUtc, true, 'MM/D/YY');
          }
          if (store.getters.userType == 'professional') {
            e.type = 'Todo';
          }
        });
        tasks.value = response.data;
      }
      loader.hide();
    };

    onMounted(() => {
      refresh();
    });

    const rowStatus = (item) => {
      if (item.isCompleted || !item.dueDateUtc) {
        return '';
      }
      const dt = dayjs(item.dueDateUtc).local().startOf('day');
      if (dayjs().isSame(dt, 'day')) return 'table-warning'; // due today
      if (dayjs().isAfter(dt, 'day')) return 'table-danger'; // overdue
      return '';
    };

    const completeTodo = async (item) => {
      if (item.type == 'GoalAction') {
        await patientApi.completeGoalAction(item.id, true);
      } else if (item.type == 'Todo') {
        await userApi.updateTodoCompletion(item.id, true);
      } else if (item.type == 'Diversion') {
        await patientApi.completeDiversion(item.id, true);
      } else return;
      await refresh();
      context.emit('goal-update');
    };

    const doDelete = async (item) => {
      if (item.type == 'Todo') await userApi.deleteTodo(item.id);
      if (item.type == 'Diversion')
        await patientApi.deleteActiveDiversion(item.id);
      await refresh();
    };

    const changeTaskFilter = (newFilter) => {
      if (newFilter == taskFilter.value) {
        taskFilter.value = 'None';
      } else {
        taskFilter.value = newFilter;
      }
      refresh();
    };

    const sortByTitle = () => {
      if (taskOrder.value == 'TaskTitleAsc') {
        taskOrder.value = 'TaskTitleDesc';
      } else {
        taskOrder.value = 'TaskTitleAsc';
      }
      refresh();
    };

    const sortByDueDate = () => {
      if (taskOrder.value == 'TaskDueOnAsc') {
        taskOrder.value = 'TaskDueOnDesc';
      } else {
        taskOrder.value = 'TaskDueOnAsc';
      }
      refresh();
    };

    const searchTasks = () => {
      pgNum.value = 1;
      taskFilter.value = 'None';
      refresh();
    };

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      'todo-container': TodoContainer,
      pgNum,
      pgSz,
      titleFilter,
      taskFilter,
      taskOrder,
      tasks,
      diversions,
      toBeDeleted,
      rowStatus,
      refresh,
      completeTodo,
      doDelete,
      changeTaskFilter,
      sortByTitle,
      sortByDueDate,
      searchTasks,
      isDirty,
      totalPages,
    };
  },
};
</script>
