<template>
  <quill-editor
    id="quill-textarea"
    ref="quill-contract-editor"
    :content="localData[property]"
    :style="[`height: ${height}`]"
    theme="snow"
    :toolbar="toolbar"
    :options="contractOptions"
    content-type="html"
    @update:content="updateInput"
  ></quill-editor>
</template>

<script>
import { ref, watch, toRef, nextTick, computed } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';

export default {
  components: {
    QuillEditor,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    // should be set to the id of a containing element in parent component
    containerId: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    // some real or contrived ID that changes whenever data prop gets reassigned (temporary fix for vue-quill reactivity bug)
    dataId: {
      type: Number,
      default: -99999,
    },
    toolbar: {
      type: String,
      default: 'essential',
    },
  },
  emits: ['update:data', 'dirty'],
  setup(props, context) {
    const localData = computed({
      get: () => props.data,
      set: (value) => context.emit('update:data', value),
    });

    // explicitly update RTE's content anytime data gets reassigned (temporary fix for vue-quill reactivity bug)
    const quillContractEditor = ref(null);
    watch(toRef(props, 'dataId'), () => {
      nextTick(() => {
        if (quillContractEditor.value) {
          quillContractEditor.value.setHTML(localData.value[props.property]);
        }
      });
    });

    // update data to reflect user input
    const updateInput = (newValue) => {
      if (quillContractEditor.value) {
        // check for a real difference to avoid inappropriately setting isDirty to true (quill editor emits a superfluous update:content event when it's first given data)
        if (
          newValue != localData.value[props.property] &&
          !(
            newValue == '' && localData.value[props.property] == '<p><br></p>'
          ) &&
          !(newValue == '<p><br></p>' && localData.value[props.property] == '')
        ) {
          context.emit('dirty');
          localData.value[props.property] = newValue;
        }
      }
    };

    // configure quill editor
    const contractOptions = {
      bounds: `#${props.containerId}`,
    };

    return {
      'quill-contract-editor': quillContractEditor,
      updateInput,
      contractOptions,
      localData,
    };
  },
};
</script>
