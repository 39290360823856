<template>
  <base-modal ref="create-todo-modal" :show-btn-classes="btnClasses" @modal-opened="clearModalData"
    @exit="$emit('clean')">
    <template #show-btn-content>
      <i class="fas fa-plus text-secondary"></i>
    </template>
    <template #header-title>New Todo [{{ date ? stdLocalDateTime(date, true, 'YYYY-MM-DD') : '' }}]</template>
    <template #body>
      <div class="row">
        <div class="col-md-6">
          <div class="row g-2">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input id="new-todo-title" v-model="title" type="text" class="form-control" placeholder="Title" />
                <label>Todo Name</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <textarea id="new-todo-description" v-model="description" class="form-control"
                  placeholder="Optional Description"></textarea>
                <label>Description (optional)</label>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-start">
              <div class="form-check">
                <input id="hasTimeValue" v-model="hasTimeValue" type="checkbox" class="form-check-input" />
                <label for="hasTimeValue" class="form-check-label">Schedule Time</label>
              </div>
            </div>
            <div v-if="hasTimeValue" class="col-12">
              <div class="form-floating mb-3">
                <Datepicker v-model="taskTime" :time-picker="true" :is24="false" />
              </div>
            </div>
            <div class="col-12 d-flex justify-content-start">
              <div class="form-check">
                <input id="isRecurring" v-model="isRecurring" type="checkbox" class="form-check-input" />
                <label for="isRecurring" class="form-check-label">Recurring</label>
              </div>
            </div>
            <div v-if="isRecurring" class="col-12">
              <select v-model="recurringPattern" class="form-select">
                <option value="">Frequency</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button id="cancel-new-todo-btn" type="button" class="btn btn-secondary" @click="cancel">
        Cancel
      </button>
      <button id="save-new-todo-btn" :disabled="!title || (isRecurring && !recurringPattern)" type="button"
        class="btn btn-success" @click="createTodo">
        Save &amp; Close
      </button>
    </template>
  </base-modal>
</template>

<script>
import { inject, ref } from 'vue';
import BaseModal from '../shared/UI/BaseModal.vue';
import stdLocalDateTime, { setLocalTimeOnLocalDate } from '../../../services/lib/localTime';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    BaseModal,
    Datepicker
  },
  props: {
    date: {
      type: Date,
      default: new Date()
    },
    btnClasses: {
      type: Object,
      default: () => { }
    }
  },
  emits: ['add-todo', 'dirty', 'clean'],
  setup(props, context) {
    const userApi = inject('api').userApi();

    const createTodoModal = ref(null);
    const toggleModal = () => {
      createTodoModal.value.toggleModal();
    };

    const openModal = () => {
      createTodoModal.value.openModal()
    }

    const title = ref('');
    const description = ref('');
    const isRecurring = ref(false)
    const recurringPattern = ref('');
    const hasTimeValue = ref(false);
    const taskTime = ref({
      hours: new Date().getHours(),
      minutes: new Date().getMinutes()
    });

    const createTodo = async () => {
      if (!isRecurring.value) recurringPattern.value = '';
      const hour = hasTimeValue.value ? taskTime.value.hours : 22;
      const minutes = hasTimeValue.value ? taskTime.value.minutes : 59;
      const localDateTime = setLocalTimeOnLocalDate(props.date, hour, minutes);

      //dayjs.utc() was not providing a utc date. Need to figure 
      //out missing config. Meantime, vanilla js gives us corrrect utc.
      const utcDueDate = localDateTime.toISOString()
      console.log('utcDueDate', utcDueDate);

      await userApi.addTodo(
        title.value,
        description.value,
        utcDueDate,
        isRecurring.value,
        recurringPattern.value,
        hasTimeValue.value
      );
      toggleModal();
      context.emit('add-todo');
      context.emit('clean');
    };

    const clearModalData = () => {
      title.value = '';
      description.value = '';
      hasTimeValue.value = false;
      taskTime.value = {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes()
      };
      isRecurring.value = false;
      recurringPattern.value = '';

      context.emit('dirty');
    };

    const cancel = () => {
      toggleModal();
      context.emit('clean');
    };

    return {
      'create-todo-modal': createTodoModal,
      toggleModal,
      title,
      description,
      createTodo,
      clearModalData,
      cancel,
      openModal,
      isRecurring,
      recurringPattern,
      hasTimeValue,
      taskTime,
      stdLocalDateTime
    };
  },
};
</script>
