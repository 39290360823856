<template>
  <button
    id="show-base-modal"
    :disabled="btnDisabled"
    type="button"
    :class="showBtnClasses"
    @click="openModal"
  >
    <slot name="show-btn-content"></slot>
  </button>
  <transition name="modal-animation">
    <div v-show="modalActive" class="modal">
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-inner">
          <!-- <div class="modal-dialog"> -->
          <div ref="modalContent" class="modal-content position-relative">
            <div class="modal-header ms-3">
              <h4 class="modal-title"><slot name="header-title"></slot></h4>
              <button
                id="close-base-modal-x"
                type="button"
                class="btn-close"
                aria-label="Close"
                @click="exit"
              ></button>
            </div>
            <div class="modal-body"><slot name="body"></slot></div>
            <div class="modal-footer">
              <slot name="footer" :toggle-modal="toggleModal">
                <button
                  id="close-base-modal"
                  type="button"
                  class="btn btn-secondary"
                  @click="toggleModal"
                >
                  Close
                </button>
              </slot>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    showBtnClasses: {
      type: Object,
      default: () => {},
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['modal-opened', 'exit'],
  setup(props, context) {
    const modalActive = ref(false);
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };

    const openModal = () => {
      context.emit('modal-opened');
      toggleModal();
    };

    const exit = () => {
      context.emit('exit');
      toggleModal();
    };

    return { modalActive, toggleModal, openModal, exit };
  },
};
</script>

<style lang="scss" scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}
.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to {
  transform: scale(0.8);
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  .modal-inner {
    position: relative;
    max-width: 640px;
    width: 80%;
    background-color: white;

    button.btn-close {
      padding: 20px 30px;
      border: none;
      font-size: 16px;
      color: #606060;
      cursor: pointer;
    }
  }
}
</style>
