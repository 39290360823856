<template>
  <div class="col p-4">
    <h4>Supervisors</h4>
    <div class="row row-cols-1 row-cols-md-2 row-cols-xxl-3 g-4">
      <supervisor-info
        v-for="supervisor in supervisors"
        :key="supervisor.id"
        :supervisor="supervisor"
        @view-profile="$emit('view-profile', supervisor.id)"
      />
    </div>
    <tbl-page-seln
      class="mt-3"
      :model-value="pgNum"
      :total-pages="totalPages"
      @updated="$emit('refresh')"
      @update:modelValue="$emit('update:pgNum', $event)"
    />
  </div>
</template>

<script>
import SupervisorInfo from './SupervisorInfo.vue';
import TblPageSeln from '../../../shared/components/TblPageSeln.vue';

export default {
  components: {
    SupervisorInfo,
    TblPageSeln,
  },
  props: {
    supervisors: {
      type: Array,
      required: true,
    },
    pgNum: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  emits: ['refresh', 'update:pgNum', 'view-profile'],
  setup() {},
};
</script>
