<template>
  <div>
    <nav>
      <ul
        class="pagination pagination-sm justify-content-center"
        :class="ulClasses"
      >
        <li
          :class="previousCls"
          :style="{ cursor: page_num == 1 ? 'default' : 'pointer' }"
          @click="goToFirstPage"
        >
          <a id="first-link" class="page-link"
            ><i class="fas fa-xs fa-chevron-left"></i
            ><i class="fas fa-xs fa-chevron-left"></i
          ></a>
        </li>
        <li
          :class="previousCls"
          :style="{ cursor: page_num == 1 ? 'default' : 'pointer' }"
          @click="previous"
        >
          <a id="prev-link" class="page-link"
            ><i class="fas fa-xs fa-chevron-left"></i
          ></a>
        </li>
        <li
          v-if="disableNext && page_num - 2 >= 1"
          class="page-item"
          style="cursor: pointer"
          @click="twoPrev"
        >
          <a class="page-link">{{ page_num - 2 }}</a>
        </li>
        <li
          v-if="page_num - 1 >= 1"
          class="page-item"
          style="cursor: pointer"
          @click="previous"
        >
          <a class="page-link">{{ page_num - 1 }}</a>
        </li>
        <li class="page-item active" style="cursor: default">
          <a class="page-link">{{ page_num }}</a>
        </li>
        <li
          v-if="page_num + 1 <= totalPages"
          class="page-item"
          style="cursor: pointer"
          @click="next"
        >
          <a class="page-link">{{ page_num + 1 }}</a>
        </li>
        <li
          v-if="page_num == 1 && 3 <= totalPages"
          class="page-item"
          style="cursor: pointer"
          @click="twoForward"
        >
          <a class="page-link">{{ page_num + 2 }}</a>
        </li>
        <li
          id="next-link"
          :class="nextCls"
          :style="{ cursor: disableNext ? 'default' : 'pointer' }"
          @click="next"
        >
          <a class="page-link"><i class="fas fa-xs fa-chevron-right"></i></a>
        </li>
        <li
          id="last-link"
          :class="nextCls"
          :style="{ cursor: disableNext ? 'default' : 'pointer' }"
          @click="goToLastPage"
        >
          <a class="page-link"
            ><i class="fas fa-xs fa-chevron-right"></i
            ><i class="fas fa-xs fa-chevron-right"></i
          ></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { onBeforeMount, ref, watch, computed } from 'vue';

export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    ulClasses: {
      type: Array,
      default: () => [],
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue', 'updated'],
  setup(props, context) {
    const page_num = ref(1);

    const disableNext = computed(() => {
      if (props.modelValue >= props.totalPages) return true;
      return false;
    });

    const previousCls = computed(() => {
      const retval = ['page-item'];
      if (page_num.value === 1) {
        retval.push('disabled');
      }
      return retval;
    });

    const nextCls = computed(() => {
      const retval = ['page-item'];
      if (disableNext.value) {
        retval.push('disabled');
      }
      return retval;
    });

    const previous = () => {
      if (page_num.value > 1) {
        page_num.value -= 1;
        context.emit('update:modelValue', page_num.value);
        context.emit('updated');
      }
    };

    const next = () => {
      if (!disableNext.value) {
        page_num.value += 1;
        context.emit('update:modelValue', page_num.value);
        context.emit('updated');
      }
    };

    const twoPrev = () => {
      page_num.value -= 2;
      context.emit('update:modelValue', page_num.value);
      context.emit('updated');
    };

    const twoForward = () => {
      page_num.value += 2;
      context.emit('update:modelValue', page_num.value);
      context.emit('updated');
    };

    const goToFirstPage = () => {
      if (page_num.value == 1) return;
      page_num.value = 1;
      context.emit('update:modelValue', page_num.value);
      context.emit('updated');
    };

    const goToLastPage = () => {
      if (page_num.value >= props.totalPages) return;
      page_num.value = props.totalPages >= 1 ? props.totalPages : 1;
      context.emit('update:modelValue', page_num.value);
      context.emit('updated');
    };

    onBeforeMount(() => {
      page_num.value = props.modelValue;
    });

    watch(
      () => props.modelValue,
      (newVal) => {
        page_num.value = newVal;
      }
    );

    return {
      previousCls,
      nextCls,
      previous,
      next,
      twoPrev,
      twoForward,
      page_num,
      disableNext,
      goToFirstPage,
      goToLastPage,
    };
  },
};
</script>
