<template>
  <div id="collapse-F" class="collapse show">
    <div ref="contacts-container" class="card-body position-relative">
      <div class="row">
        <div class="col-lg-4 col-xxl-3">
          <!-- find patient -->
          <form class="mb-3" @submit.prevent="refresh">
            <div class="input-group">
              <div class="form-floating form-floating-group flex-grow-1">
                <input v-model="filter" type="text" class="form-control" name="searchPatient"
                  placeholder="Find Patient" />
                <label for="searchPatient">Search Contacts</label>
              </div>
              <button type="submit" class="input-group-text btn-sm btn-primary px-3">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </form>

          <!-- Add Contact -->
          <button class="btn btn-sm btn-sky mb-3 w-100" data-bs-toggle="collapse" data-bs-target="#newPatient">
            Add Contact
          </button>
          <div id="newPatient" class="collapse shadow p-3 mb-4">
            <form>
              <div class="form-floating mb-3">
                <input id="firstName" v-model="firstName" type="Text" class="form-control"
                  :class="{ 'is-invalid': firstNameIssue }" placeholder="First Name" @input="firstNameIssue = ''" />
                <label for="firstName">First Name</label>
                <div v-if="firstNameIssue" class="m-1 small text-danger">
                  {{ firstNameIssue }}
                </div>
              </div>
              <div class="form-floating mb-3">
                <input id="lastName" v-model="lastName" type="Text" class="form-control"
                  :class="{ 'is-invalid': lastNameIssue }" placeholder="Last Name" @input="lastNameIssue = ''" />
                <label for="lastName">Last Name</label>
                <div v-if="lastNameIssue" class="m-1 small text-danger">
                  {{ lastNameIssue }}
                </div>
              </div>
              <div class="form-floating mb-3">
                <input id="email" v-model="email" type="email" class="form-control" :class="{ 'is-invalid': emailIssue }"
                  placeholder="Email Address" @input="emailIssue = ''" />
                <label for="email">Email Address</label>
                <div v-if="emailIssue" class="m-1 small text-danger">
                  {{ emailIssue }}
                </div>
              </div>
              <div class="form-floating mb-3">
                <input id="occupation" v-model="occupation" type="occupation" class="form-control"
                  :class="{ 'is-invalid': occupationIssue }" placeholder="Occupation" @input="occupationIssue = ''" />
                <label for="email">Occupation</label>
                <div v-if="occupationIssue" class="m-1 small text-danger">
                  {{ occupationIssue }}
                </div>
              </div>
              <div class="form-floating mb-3">
                <input id="phone" v-model="phone" type="phone" class="form-control" :class="{ 'is-invalid': phoneIssue }"
                  placeholder="Phone Number" @input="phoneIssue = ''" />
                <label for="phone">Phone Number</label>
                <div v-if="phoneIssue" class="m-1 small text-danger">
                  {{ phoneIssue }}
                </div>
              </div>
              <div class="form-floating mb-3">
                <input id="address" v-model="address" type="address" class="form-control"
                  :class="{ 'is-invalid': addressIssue }" placeholder="address" @input="addressIssue = ''" />
                <label for="address">Address</label>
                <div v-if="addressIssue" class="m-1 small text-danger">
                  {{ addressIssue }}
                </div>
              </div>
              <div class="text-end">
                <button ref="cancel-btn" type="button" class="btn btn-secondary me-3" data-bs-toggle="collapse"
                  data-bs-target="#newPatient" @click="clearForm">
                  Cancel
                </button>
                <button type="button" :disabled="!(firstName &&
                  lastName &&
                  occupation &&
                  email &&
                  phone &&
                  address)
                  " class="btn btn-success" @click="addContact">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col">
          <h4>Contacts</h4>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-xxl-3 g-3">
            <contact-info v-for="contact in contacts" :key="contact.id" :contact="contact"
              @delete="deleteContact(contact.id)" @updated="refresh" @clean="$emit('clean')" @dirty="$emit('dirty')" />
          </div>
          <tbl-page-seln v-model="pgNum" class="mt-3" :total-pages="totalPages" @updated="refresh" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import ContactInfo from './ContactInfo.vue';
import {
  getLodashArray,
  getPage,
} from '../../../../../services/lib/clientPaging';
import TblPageSeln from '../../../shared/components/TblPageSeln.vue';

export default {
  components: {
    ContactInfo,
    TblPageSeln,
  },
  emits: ['clean', 'dirty'],
  setup() {
    const userApi = inject('api').userApi();
    const loading = inject('$loading');

    const contactsContainer = ref(null);
    const contacts = ref([]);
    const filter = ref('');
    const pgNum = ref(1);
    const pgSz = ref(10);
    const totalPages = ref(0);

    onMounted(() => {
      refresh();
      scrollToElement();
    });
    const targetId = ref('collapse-F')
    const scrollToElement = () => {
      const el = document.getElementById(targetId.value);

      if (el) {
        el.scrollIntoView();
      }
    }
    const refresh = async () => {
      const loader = loading.show({
        container: contactsContainer.value,
        isFullPage: false,
      });
      const response = await userApi.getContacts();
      const allContacts = response.data;
      const result = getPage(
        getLodashArray(allContacts), // items include a generated 'ts' property to sort on
        pgNum.value - 1, // page to display
        pgSz.value, // page size
        (contact) =>
          contact.firstName
            ?.toLowerCase()
            .includes(filter.value.toLowerCase()) ||
          contact.lastName?.toLowerCase().includes(filter.value.toLowerCase()) ||
          contact.occupation
            ?.toLowerCase()
            .includes(filter.value.toLowerCase()) ||
          contact.email?.toLowerCase().includes(filter.value.toLowerCase()) ||
          contact.phone?.toLowerCase().includes(filter.value.toLowerCase()) ||
          contact.address?.toLowerCase().includes(filter.value.toLowerCase()), // no filter
        'lastName', // sorting property
        'asc' // sorting direction
      );
      contacts.value = result.data;
      totalPages.value = result.totalPages;
      loader.hide();
    };

    const firstName = ref('');
    const lastName = ref('');
    const occupation = ref('');
    const email = ref('');
    const phone = ref('');
    const address = ref('');
    const cancelBtn = ref(null);

    const addContact = async () => {
      if (!validateInput()) return;
      const loader = loading.show({
        container: contactsContainer.value,
        isFullPage: false,
      });
      await userApi.addContact(
        firstName.value,
        lastName.value,
        occupation.value,
        email.value,
        phone.value,
        address.value
      );
      loader.hide();
      clearForm();
      cancelBtn.value.click();
      refresh();
    };

    const clearForm = () => {
      firstName.value = '';
      lastName.value = '';
      occupation.value = '';
      email.value = '';
      phone.value = '';
      address.value = '';
      firstNameIssue.value = '';
      lastNameIssue.value = '';
      emailIssue.value = '';
      occupationIssue.value = '';
      phoneIssue.value = '';
      addressIssue.value = '';
    };

    const deleteContact = async (id) => {
      const loader = loading.show({
        container: contactsContainer.value,
        isFullPage: false,
      });
      await userApi.deleteContact(id);
      loader.hide();
      refresh();
    };

    const editContact = async (contact) => {
      const loader = loading.show({
        container: contactsContainer.value,
        isFullPage: false,
      });
      await userApi.editContact(contact);
      loader.hide();
      refresh();
    };

    const firstNameIssue = ref('');
    const lastNameIssue = ref('');
    const emailIssue = ref('');
    const occupationIssue = ref('');
    const phoneIssue = ref('');
    const addressIssue = ref('');

    const validateInput = () => {
      firstNameIssue.value = '';
      lastNameIssue.value = '';
      emailIssue.value = '';
      occupationIssue.value = '';
      phoneIssue.value = '';
      addressIssue.value = '';

      let isValid = true;

      if (firstName.value.length >= 80 || !firstName.value) {
        firstNameIssue.value = 'First name is required or invalid';
        isValid = false;
      }
      if (lastName.value.length >= 80 || !lastName.value) {
        lastNameIssue.value = 'Last name is required or invalid';
        isValid = false;
      }
      if (occupation.value.length >= 80 || !occupation.value) {
        occupationIssue.value = 'Occupation is required or invalid';
        isValid = false;
      }
      if (email.value.length >= 50 || !email.value || !/@/.test(email.value)) {
        emailIssue.value = 'Email Address is required or invalid';
        isValid = false;
      }
      if (!phone.value) {
        phoneIssue.value = 'Phone Number is required';
        isValid = false;
      }
      /* eslint-disable no-useless-escape */
      if (!/^(?:(?:\+\d{1,3}\s?)?(?:\(\d{1,3}\)\s?)?|(?:\d{1,3}[-.\s]?))?\d{1,}[-.\s]?\d{1,}[-.\s]?\d{1,}[-.\s]?\d{1,}$/.test(phone.value) || phone.value.length >= 20 || phone.value.length < 10) {
        phoneIssue.value = 'Please enter a valid phone number in the format +1 (234) 567-890';
        isValid = false;
      }
      if (address.value.length >= 200 || !address.value) {
        addressIssue.value = 'Address is required or invalid';
        isValid = false;
      }
      return isValid;
    };

    return {
      'contacts-container': contactsContainer,
      contacts,
      filter,
      firstName,
      lastName,
      occupation,
      email,
      phone,
      address,
      addContact,
      'cancel-btn': cancelBtn,
      clearForm,
      refresh,
      deleteContact,
      editContact,
      firstNameIssue,
      lastNameIssue,
      emailIssue,
      phoneIssue,
      addressIssue,
      occupationIssue,
      pgNum,
      totalPages,
    };
  },
};
</script>
