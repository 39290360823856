<template>
  <div id="collapse-E" class="collapse show">
    <div class="card-body">
      <div ref="supervision-container" class="position-relative">
        <div class="row">
          <!-- <div class="col mt-1 mb-2">
            <a class="btn btn-secondary" href="https://bhealthy.cloudu.com/Course/Start?courseID=94&lessonId=310" >Learn More</a>
          </div> -->
        </div>
        <div class="row">
          <supervisor-search
            v-model:lastName="lastName"
            v-model:city="city"
            v-model:stateCode="stateCode"
            v-model:postalCode="postalCode"
            v-model:specialtyId="specialtyId"
            :specialties="specialties"
            @search="searchSupervisors"
          />
          <supervisor-list
            v-model:pgNum="pgNum"
            :supervisors="supervisors"
            :total-pages="totalPages"
            @refresh="refresh"
            @view-profile="
              $router.push({
                name: 'pro-view-pro',
                params: { uid: $event },
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import SupervisorSearch from './SupervisorSearch.vue';
import SupervisorList from './SupervisorList.vue';

export default {
  components: {
    SupervisorSearch,
    SupervisorList,
  },
  setup() {
    const profApi = inject('api').profApi();
    const generalDataApi = inject('api').generalDataApi();
    const loading = inject('$loading');

    const specialties = ref([]);
    onMounted(async () => {
      const loader = loading.show({
        container: supervisionContainer.value,
        isFullPage: false,
      });
      const response = await generalDataApi.specialties();
      specialties.value = response.data;
      loader.hide();
      refresh();
    });

    const supervisors = ref([]);
    const pgNum = ref(1);
    const pgSz = ref(4);
    const lastName = ref('');
    const city = ref('');
    const stateCode = ref('');
    const postalCode = ref('');
    const specialtyId = ref('');
    const totalPages = ref(0);
    const supervisionContainer = ref(null);

    const refresh = async () => {
      const loader = loading.show({
        container: supervisionContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getSupervisors(
        pgSz.value,
        pgNum.value - 1,
        lastName.value,
        city.value,
        stateCode.value,
        postalCode.value,
        specialtyId.value
      );
      supervisors.value = response.data.data;
      totalPages.value = response.data.totalPages;

      loader.hide();
    };

    const searchSupervisors = () => {
      pgNum.value = 1;
      refresh();
    };

    return {
      'supervision-container': supervisionContainer,
      supervisors,
      pgNum,
      pgSz,
      lastName,
      city,
      stateCode,
      postalCode,
      totalPages,
      refresh,
      searchSupervisors,
      specialties,
      specialtyId,
    };
  },
};
</script>
